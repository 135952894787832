

import React, { useContext, useEffect, useState } from "react";
const ApiDataContext = React.createContext({});

const ApiDataContextProvider = ({ children }) => {
    const [ApiData, setApiData] = useState({});
    useEffect(() => {
        
        var t = localStorage.getItem("datacollection");
        if (t !== null) {
            setApiData({ ...JSON.parse(t) });
        }
    }, [])
    useEffect(() => {
        localStorage.setItem('datacollection', JSON.stringify({ ...ApiData }));
    }, [ApiData])
    return (
        <ApiDataContext.Provider value={{
            ApiData,
            setApiData,

        }}>
            {children}
        </ApiDataContext.Provider>
    )
};

export {
    ApiDataContext,
    ApiDataContextProvider
};