import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox, CheckBoxOutlineBlankRounded } from "@material-ui/icons";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";

import { AlertDataContext } from "../Context/Alert.context";
import { FunctionsContext } from "../Context/Functions.context";
import Check from '../../Assets/ready.svg'
import requestOnLivness from "fas-web-ui-components/requestModule"

const BasicDetails = () => {
    const { setActive, setComplete } = useContext(StepsContext);
    const { setLoading } = useContext(AlertDataContext)
    const { LivenessConsent, Liveness } = useContext(FunctionsContext)
    const [countdown, setcountdown] = useState(60);
    const history = useHistory();
    const { data, setData, setvalue } = useContext(DataContext);
    const [selfie, setSelfie] = useState(false)
    const [text, setText] = useState("")
    const [obj, setobj] = useState({});
    const [base64img, setbase64img] = useState("");
    const camera = React.createRef();
    useEffect(() => {
        let event = new Event("openCamera");
        camera.current.dispatchEvent(event)
        camera.current.addEventListener("capture", handleCapture);
        return () => {

        }
    }, [])
    const handleCapture = async event => {
        const photos = event.detail.photos;
        
        setText("");
        var base64 = await blobToBase64(photos[0]);

        setLoading(true);
        var t = await Liveness(base64);
        if (t.StatusCode === 0) {
            setText(t.ResponseObject.error);
            setobj(t.ResponseObject);
            if (parseFloat(t.ResponseObject.probability).toFixed(2) > 0.6) {
                setSelfie(true)
                setbase64img(base64);
            } else {
                setSelfie(false)
            }

        }
        setLoading(false);
        let es = new Event("closeCamera");
        camera.current.dispatchEvent(es)
    };

    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }
    const checkotp = async (event) => {
        event.preventDefault();
        if (!selfie) {
            alert("Liveness Test is required");
            return;
        }
        // let eves = new Event("closeCamera");
        // camera.current.dispatchEvent(eves)
        var a = await LivenessConsent(data);
        if (a.StatusCode === 0) {

            history.push("cnicverification")
        }
    }


    return (
        <Animatedpage submit={checkotp}>
            <Grid item xs={12} id="s1q2">
                <Row>
                    <h3 className="Q">Photo requirements</h3>
                    <h3 className="Q">Your photos must be:</h3>
                    <ul style={{ marginBottom: 10 }}>
                        <li>Clear, sharp and in focus</li>
                        <li>Taken with a neutral facial expression</li>
                        <li>Eyes open and clearly visible</li>
                        <li>Mouth closed, neutral expression</li>
                        <li>In case of minor account request, live photo of minor will be required.</li>
                    </ul>
                    <h3 className="Q">Place your face in the frame and look towards camera. Your photo will be captured automatically.</h3>
                    {selfie ? <></> :
                        <camera-component
                            url_logo="../blue.png"
                            is_multiframe={"false"}
                            disable_control_panel={true}
                            timer={false}
                            show_mask={"true"}
                            background_color="#f7f8fa"
                            ref={camera}
                            face_detection={true}
                            model_path="/models/"
                        />
                    }
                    {text !== "" && !selfie ?
                        <table style={{ display: "inline-block" }}>
                            <thead>
                                <tr>
                                    <th>Probability</th>
                                    <th>Quality</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{parseFloat(obj.probability).toFixed(2)}</td>
                                    <td>{parseFloat(obj.quality).toFixed(2)}</td>
                                    <td>{parseFloat(obj.score).toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                        : <></>}
                    {
                        selfie ? <>
                            <br />
                            <img src={base64img} style={{ width: 200, height: 150 }} />
                            <br />
                            <img src={Check} style={{ width: 50 }} />
                            <label className="Q">Liveness Test Successful</label>
                            <br />
                        </> : <>
                            {text !== "" ?
                                <>
                                    <h3 className="Q" style={{ color: "red" }}>Please try again.</h3>
                                    <h3 className="Q" style={{ color: "red" }}>Note: {text}</h3>
                                    <button type="button" style={{ margin: 10, background: "var(--light)", border: "none", borderRadius: 10, marginTop: 20 }}
                                        onClick={() => {
                                            let event = new Event("openCamera");
                                            camera.current.dispatchEvent(event);
                                        }}>Try Again</button>
                                    <button type="button"
                                        style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                                        onClick={() => {setSelfie(false); let event = new Event("takePhoto"); camera.current.dispatchEvent(event) }}>Capture</button>
                                </>
                                : <>
                                    <button type="button" style={{ margin: 10, background: "var(--light)", border: "none", borderRadius: 10, marginTop: 20 }}
                                        onClick={() => {
                                            let event = new Event("openCamera");
                                            camera.current.dispatchEvent(event);
                                        }}>Open Camera</button>
                                    <button type="button"
                                        style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                                        onClick={() => {setSelfie(false); let event = new Event("takePhoto"); camera.current.dispatchEvent(event) }}>Capture</button>
                                </>}
                        </>
                    }



                    {/*                     
                    <h3>This option is only for UAT</h3>
                    <button type="button"
                        style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }}
                        onClick={() => {setSelfie(true)}}>Ignore</button> */}

                    <div className="btndiv">
                        <button style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.goBack()}>
                            <ArrowLeft style={{ marginLeft: -10 }} />
                            Back
                        </button>
                        <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>Next
                            <ArrowRight style={{ marginRight: -10 }} />
                        </button>
                    </div>
                </Row>
            </Grid>
        </Animatedpage >
    )

}
export default BasicDetails;