


const CustomCheck = ({ text, list, Name, required, setvalue }) => {
    const val = list.filter(p => p.Name === Name)[0];
    return (<>
        <label for={Name} className="" style={{ marginBottom: 20 }}>
            <input type="checkbox" id={Name} name={Name} value={val.id} onChange={(txt) => setvalue(txt)} style={{ marginRight: 5, width: 18, height: 18 }} required={required} />
            {val.Discription}
        </label>
    </>);

}

export default CustomCheck;