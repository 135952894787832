import React, { useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";

const RemitterInfo = () => {
    const { data, setData, setvalue } = useContext(DataContext);
    const { setActive, setComplete } = useContext(StepsContext);

    const history = useHistory();

    useEffect(() => {
       setActive("Remitter Details");
    }, [])
    return (
        <Animatedpage useauth={true}>
            <Grid item xs={12} id="s1q2">
                <Row>
                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                        <h1 className="Q">Name</h1>
                        
                        <input className="inputtxt" name="remitterName" value="" onChange={(txt) => setvalue(txt)} required />
                        <p className="Qhelper2">Please mention the name of person who will be prospective remitter</p>
                    </div> 

                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                        <h1 className="Q">Relationship</h1>
                        
                        <input className="inputtxt" name="remitterRelation" value="" onChange={(txt) => setvalue(txt)} required />
                        <p className="Qhelper2">Please mention the relation with prospective remitter</p>
                    </div> 

                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                        <h1 className="Q">Legal Identification No.</h1>
                        
                        <input className="inputtxt" name="remitterlegalID" value="" onChange={(txt) => setvalue(txt)} required />
                        <p className="Qhelper2">Please mention the legal ID of prospective remitter</p>
                    </div>                    


                    <div className="btndiv">
                        <Button variant="contained" style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() =>
                            history.goBack()
                        }>
                            <ArrowLeft style={{ marginLeft: -10 }} />
                            Back
                        </Button>
                        <Button variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => { history.push("cnc"); }}>Next
                            <ArrowRight style={{ marginRight: -10 }} />
                        </Button>
                    </div>
                </Row>
            </Grid>
        </Animatedpage>
    );
}

export default RemitterInfo;