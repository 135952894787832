import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Animatedpage from './animatepage';
import { NavMenu } from './NavMenu';
import Row from './Row';

export class Layout extends Component {
  static displayName = Layout.name;
  
  render() {
    return (
      <div>
        {/* <NavMenu />         */}
        <Container style={{ margin: 0 }}>
          {this.props.children}
        </Container>
      </div>
    );
  }
}
