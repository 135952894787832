import React, { useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import { StepsContext } from "../Context/Steps.Context";
import Blue from '../../Assets/blue.png'

import { useHistory } from "react-router";
import Animatedpage from "../animatepage";


const AccountType = () => {
    const { setActive } = useContext(StepsContext);
    useEffect(() => {
        setActive("");
    }, [])
    return (
        <Animatedpage>
            <Grid item xs={12} style={{ marginTop: 50 }}>
                <Row className="slide">
                    <div>
                        {/* <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={DocA} download>Products List &amp; Features</a></h5>
                        <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={DocB} download>Account Opening Documents</a></h5>
                        <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={DocC} download>Key Fact Statement</a></h5>
                        <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={DocCMustaqeem} download>Key Fact Statement (Mustaqeem)</a></h5>
                        <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={CardFacilities} download>Soneri Debit Card Facilities</a></h5>
                        <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={FATCA} download>FATCA Questions</a></h5>
                        <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={Zakat} download>Zakat Exemption Declaration for Muslims</a></h5>
                        <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={AAC} download>T&C Asaan Account Conventional</a></h5>
                        <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={AAI} download>T&C Asaan Account Islamic PD Vetted</a></h5>
                        <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={ARC} download>T&C Asaan Remittance Conventional</a></h5>
                        <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={ARM} download>T&C Asaan Remittance Mustaqeem</a></h5> */}
                    </div>
                </Row>
            </Grid>
        </Animatedpage>
    )
}

export default AccountType;