import React from "react";
import Particles from "react-tsparticles";

export default function Confetti() {
  return (
    <Particles
      options={{
        background: {
          color: {
            value: "#fff"
          }
        },
        fullScreen: {
          enable: true,
          zIndex: -1
        },
        interactivity: {
          detectsOn: "window"
        },
        emitters: {
          position: {
            x: 70,
            y: 0
          },
          rate: {
            quantity: 20,
            delay: 0.5
          }
        },
        particles: {
          color: {
            value: ["#1E00FF", "#FF0061", "#E1FF00", "#00FF9E"]
          },
          move: {
            decay: 0.05,
            direction: "top",
            enable: true,
            gravity: {
              enable: true,
              maxSpeed: 150
            },
            outModes: {
              top: "none",
              default: "destroy"
            },
            speed: { min: 25, max: 50 }
          },
          number: {
            value: 0
          },
          opacity: {
            value: 1
          },
          rotate: {
            value: {
              min: 0,
              max: 360
            },
            direction: "random",
            animation: {
              enable: true,
              speed: 30
            }
          },
          tilt: {
            direction: "random",
            enable: true,
            value: {
              min: 0,
              max: 360
            },
            animation: {
              enable: true,
              speed: 30
            }
          },
          size: {
            value: 5
          },
          roll: {
            darken: {
              enable: true,
              value: 25
            },
            enable: true,
            speed: {
              min: 5,
              max: 15
            }
          },
          wobble: {
            distance: 30,
            enable: true,
            speed: {
              min: -7,
              max: 7
            }
          },
          shape: {
            type: ["square"]
          }
        }
      }}
    />
  );
}
