import React, { useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import { FunctionsContext } from "../Context/Functions.context";
import { AlertDataContext } from "../Context/Alert.context";
import { CheckAlpha, CheckAplhaChara, CheckNumeric } from "../service";

const BeneficialInfo = () => {
    const { data, setData, setvalue } = useContext(DataContext);
    const { setActive, setComplete } = useContext(StepsContext);

    const history = useHistory();

    useEffect(() => {
        setActive("Fund Provider");
    }, [])
    return (
        <Animatedpage>
            <Grid item xs={12} id="s1q2">
                <Row>
                    <h1 className="Q">Please provide the details of Fund Provider along with supporting documents.</h1>
                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                        <h1 className="Q">Name</h1>
                        <input className="inputtxt" name="BeneficialName"  onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} required />
                        <p className="Qhelper2">As per NADRA ID document</p>
                    </div>
                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>2</b> /7</p> */}
                        <h1 className="Q">Legal ID No</h1>
                        <input className="inputtxt" name="BeneficialLegalID"  onChange={(txt) => CheckNumeric(txt, () => { setvalue(txt) }, () => { })} required />
                        <p className="Qhelper2"></p>
                    </div>
                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> /7</p> */}
                        <h1 className="Q">Address</h1>
                        <input className="inputtxt" name="BeneficialAddress"  onChange={(txt) => CheckAplhaChara(txt, () => { setvalue(txt) }, () => { })} required />
                        <p className="Qhelper2">House no., Street no, Area, Sector</p>
                    </div>
                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>4</b> /7</p> */}
                        <h1 className="Q">Mobile No.</h1>
                        <input className="inputtxt" name="BeneficialMobile"  onChange={(txt) => CheckAplhaChara(txt, () => { setvalue(txt) }, () => { })} required />
                        <p className="Qhelper2"></p>
                    </div>
                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>5</b> /7</p> */}
                        <h1 className="Q">Relationship</h1>
                        <input className="inputtxt" name="BeneficialRelationship"  onChange={(txt) => CheckAplhaChara(txt, () => { setvalue(txt) }, () => { })} required />
                        <p className="Qhelper2"></p>
                    </div>
                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>6</b> /7</p> */}
                        <h1 className="Q">Legal ID Document</h1>
                        <input type={"file"} multiple={false} name="Beneficialdoc" onChange={(txt) => setvalue(txt)} required />
                        <p className="Qhelper" style={{ marginTop: 5, fontSize: 12 }}>Attach legal ID document of beneficial owner (PNG, PDF, BMP, GIF or JPEG)</p>
                    </div>
                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>7</b> /7</p> */}
                        <h1 className="Q">Source Of Income Evidence</h1>
                        <input type={"file"} multiple={false} name="Beneficialincomedoc" onChange={(txt) => setvalue(txt)} required />
                        <p className="Qhelper" style={{ marginTop: 5, fontSize: 12 }}>Attach source of income evidence of beneficial owner</p>
                    </div>
                    <>
                        <label for="occchk" className="">
                            <input type="checkbox" id="occchk" style={{ marginRight: 5, width: 18, height: 18 }} />
                            I do hereby confirm and undertake that I have been financially supported by the individual whose details are provided above and he/she will be treated as fund provider of this account in Faysal Bank Bank Limited.</label >
                    </>
                    <div className="btndiv">
                        <Button variant="contained" style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() =>
                            history.goBack()
                        }>
                            <ArrowLeft style={{ marginLeft: -10 }} />
                            Back
                        </Button>
                        <Button variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => { history.push("znt"); }}>Next
                            <ArrowRight style={{ marginRight: -10 }} />
                        </Button>
                    </div>
                </Row>
            </Grid>
        </Animatedpage>
    );
}

export default BeneficialInfo;