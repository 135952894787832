import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Recaptcha from 'react-recaptcha'
import Animatedpage from "../animatepage";
import { ResentOtp } from "../service";
import { AlertDataContext } from "../Context/Alert.context";
import { FunctionsContext } from "../Context/Functions.context";
import { ApiDataContext } from "../Context/Api.context";
import { Config } from "../congig";


const BasicDetails = () => {
    const { data, setData, setvalue } = useContext(DataContext);
    const { setActive, setComplete } = useContext(StepsContext);
    const { showInfo, showError, setLoading } = useContext(AlertDataContext);
    const { ApiData, setApiData } = useContext(ApiDataContext)

    const { VerifyOtp } = useContext(FunctionsContext)
    const [Captcha, setCaptcha] = useState("")
    const [otp, setotp] = useState("")
    const [otpEmail, setotpEmail] = useState("")

    const [countdown, setcountdown] = useState(120);
    const history = useHistory();
    var callback = function () {

    };
    var verifyCallback = function (response) {
        setCaptcha(true);
    };
    var expiredCallback = function (response) {
        setCaptcha(false)
    }
    useEffect(() => {
        setActive("Basic Details & OTP Verification");

    }, [])
    useEffect(() => {
        countdowntimer('clock', 3, 0);
    }, []);
    function countdowntimer(element, minutes, seconds) {
        var time = minutes * 60 + seconds;
        var interval = setInterval(function () {
            var el = document.getElementById("element");
            if (time == 0) {
                clearInterval(interval);
                var t = document.createElement("p");
                t.onclick = function () { resend(); countdowntimer('clock', 3, 0); };
                t.classList.add("Qhelper");
                t.style.textAlign = "center";
                t.style.backgroundColor = "var(--secondary)";
                t.style.color = "white";
                t.style.width = "110px";
                t.style.padding = "10px";
                t.style.cursor = "pointer";
                t.innerText = "Re-sent OTP";
                el.innerHTML = "";
                el.appendChild(t);
                //el.innerHTML = "<p onClick={() => { resend(); countdown('clock', 3, 0); }} className='Qhelper' style={{ textAlign: 'center', marginBottom: 10, background: 'var(--secondary)', border: 'none', borderRadius: 10, marginTop: 0, width: 110, cursor: 'pointer', padding: 10, color: 'white' }}>Resend OTP</p>";
                // setTimeout(function () {
                //     countdowntimer('clock', 2, 0);
                // }, 2000);
            }
            var minutes = Math.floor(time / 60);
            if (minutes < 10) minutes = "0" + minutes;
            var seconds = time % 60;
            if (seconds < 10) seconds = "0" + seconds;
            var text = "OTP expire in " + minutes + ':' + seconds;
            if (time > 0) {
                el.innerHTML = text;
            }
            time--;

        }, 1000);
    }
    const datasubmit = async (e) => {
        //e.preventDefault();
        if (Captcha || !Config.robot) {
            setLoading(true);
            var resp = await VerifyOtp(data, otp, otpEmail);
            setLoading(false);

            if (resp.StatusCode === 0) {
                setApiData({ ...ApiData, ...resp.ResponseObject });
                //history.push("/accountoption");
                window.location.href = "/accountoption";
            } else {
                showError(resp.StatusMessage);
            }
        } else {
            alert("Validate Captcha")
        }
    }
    const resend = async () => {

        setLoading(true);
        var resp = await ResentOtp(data);
        setLoading(false);

        countdowntimer('clock', 3, 0);
    }


    return (
        <Animatedpage submit={datasubmit}>
            <Grid item xs={12} id="s1q2">
                <Row>
                    <div>
                        {/* <p className="Qhelper">Phone Number : <b>+92{data.mnumber}</b></p> */}
                        <p className="Qhelper">Email : <b>{data.email}</b></p>
                    </div>
                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> / 1</p> */}
                        {/* <p className="Qhelper">Enter the OTP code sent to your provided mobile number.</p>
                        <input className="inputtxt" type={"password"} minLength={4} maxLength={4} name="otp" onChange={(txt) => setotp(txt.target.value)} required /> */}
                        <p className="Qhelper">Enter the OTP code sent to your provided Email address.</p>
                        <input className="inputtxt" type={"password"} minLength={6} maxLength={6} name="otp" onChange={(txt) => setotpEmail(txt.target.value)} required />
                        <div id="element" style={{ margin: 20 }}></div>
                        {Config.robot ?
                            <Recaptcha
                                sitekey="6Ld_88YcAAAAAB179MAic7n2N5tgDn6jHERoYKew"
                                render="explicit"
                                verifyCallback={verifyCallback}
                                onloadCallback={callback}
                                expiredCallback={expiredCallback}
                            /> : null}

                    </div>
                    <label for="otpchk" className="" style={{ marginBottom: 20 }}>
                        <input type="checkbox" id="otpchk" name="otpchk" onChange={(txt) => setvalue(txt)} style={{ marginRight: 5, width: 18, height: 18 }} required />
                        All correspondence with the customer will be carried out on above-mentioned email address unless changed in the future by the customer.
                    </label >
                    <div className="btndiv">
                        <button style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.goBack()}>
                            <ArrowLeft style={{ marginLeft: -10 }} />
                            Back
                        </button>
                        <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>Next
                            <ArrowRight style={{ marginRight: -10 }} />
                        </button>
                    </div>
                </Row>
            </Grid>
        </Animatedpage>
    )

}
export default BasicDetails;