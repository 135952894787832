const screen = {
    fistscreen: 0,
    otp: 1,
    //accountoption : 3,
    //otherdetails : 4,
    basicdetails: 4,//
    otherinfo: 5,//
    finalize: 6,
    individual: 7,
    znt: 8,
    cnc: 9,
    tnc: 10,
    liveness: 11,
    cnicverification: 12,
}

export {
    screen
}